@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@keyframes boxModal {
  0% {
    transform: scale(1.2);
    opacity: 0.1;
  }

  50% {
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes Modal {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
p,
small,
button,
input {
  font-family: "Raleway", sans-serif;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0px !important;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: #ffffff;
  text-decoration: none;
}

.toggle-box {
  width: 80px;
  height: 40px;
  background-color: transparent;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  transition: 0.5s;
  transform: scale(0.7);
  cursor: pointer;

  .toggle {
    width: 32px;
    height: 32px;
    border-radius: 2rem;
    transition: 0.3s;
  }
}

.off-b {
  border: solid 2px #f28e7d;
}

.on-b {
  border: solid 2px #6ceb6c;
}

.off {
  background-color: #f28e7d;
  border: solid 2px #f28e7d;
}

.on {
  background-color: #6ceb6c;
  border: solid 2px #7df27d;
  margin-left: 40px;
}

.btn_secondary {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: transparent;
  border: none;
  border-radius: 3rem;
  transition: 0.3s;
  font-weight: 500;

  i {
    margin-right: 5px;
    margin-left: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_secondary:hover {
  border: solid 1px;
}

.btn_primary {
  display: flex;
  min-width: 150px;
  width: auto;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: #ff7900;
  border: none;
  border-radius: 3rem;
  transition: 0.3s;
  font-weight: 500;
  padding: 15px 25px;

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_primary:hover {
  background: #c95e00;

  i,
  p {
    color: white;
  }
}

.btn_primary_2 {
  display: flex;
  min-width: 150px;
  width: auto;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: #6851fb;
  border: none;
  border-radius: 3rem;
  transition: 0.3s;
  font-weight: 500;
  padding: 15px 25px;

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_primary_2:hover {
  background: #4030a7;

  i,
  p {
    color: white;
  }
}

.btn_aceptar {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: #7cd58f;
  border: none;
  border-radius: 2px;
  transition: 0.3s;
  font-weight: 500;

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_aceptar:hover {
  background: #5ca56c;
}

.btn_negar {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: #ff3434;
  border: none;
  border-radius: 2px;
  transition: 0.3s;
  font-weight: 500;

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_negar:hover {
  background: #ad2424;
}

.btn_reprogramar {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ffffff;
  background: #fd7822;
  border: none;
  border-radius: 2px;
  transition: 0.3s;
  font-weight: 500;

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0px;
  }
}

.btn_reprogramar:hover {
  background: #c25e1c;
}

.voltar-btn {
  width: auto;
  height: 50px;
  border-radius: 3rem;
  position: absolute;
  top: 5%;
  left: 5%;
  border: none;
  background-color: #6851fb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  box-shadow: rgba(0, 0, 0, 0.315) 0px 2px 10px;

  p {
    font-weight: 500;
  }

  i {
    margin-right: 10px;
  }
}

.qtd {
  height: 35px;
  border-radius: 3px;
}

.spacing {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(#7150ff, #8f76ff, #c3b6ff, #ffffff);
}

@media (max-width: 1366px) {
  .spacing {
    height: 200px;
  }
}

@mixin height-card {
  height: 290px !important;
}

$colors: (
  laranja: #ff7900,
  roxoPrimario: #6851fb,
  branco: #ffffff,
  preto: #272727,
  pretoTexto: #606060,
  cinzaEscuro: #a0a0a0,
  azulClaro: #b9def7,
  cinza: #d8d8d8,
  vermelho: #ff3434,
  verde: #7cd58f,
  azul: #1e90ff,
);
