@import "../../../_variables";

.btn_blue {
    background-color: #338aff;
    color: #FFF;
}

.btn_orange {
    background-color: #ffa833;
    color: #FFF;
}

.btn_green {
    background-color: #7CD58F;
    color: #FFF;
}

.btn_cancel {
    background-color: #fff;
    color: #ff3933;
    border: solid 1px #ff3933 !important;
}

.modal-back {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.358);
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border: none;
        border-radius: 25px;
        font-weight: 600;
        cursor: pointer;

        i {
            margin-right: 10px;
        }
    }

    .modal-box {
        width: 400px;
        height: auto;
        animation: boxModal 0.5s;
        width: 500px;
        height: auto;
        background-color: map-get($colors, branco);
        border-radius: 10px;
        box-shadow: 5px 5px 10px #00000034;
        display: flex;
        flex-direction: column;
        padding: 10px 5px 10px 5px;

        .header-modal {
            height: 30px;
            width: 100%;
            display: flex;
            align-items: center;
        }

        h5 {
            background: -webkit-linear-gradient(45deg, #6851FB, #FF7900 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        i {
            font-size: 22px;
            margin-right: 10px;
            color: map-get($colors, roxoPrimario);
        }

        .dados-inf {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            input {
                width: 100%;
            }
        }

        .input-box {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: map-get($colors, branco);
            margin-bottom: 15px;
    
            small {
                font-size: 14px;
                color: map-get($colors, roxoPrimario);
            }
    
            input {
                width: 100%;
                height: 40px;
                background: #f7f7f7;
                color: #3d3d3d;
                border: none;
                font-size: 22px;
                font-weight: 500;
                outline: none;
                transition: 0.3s;
                border-radius: 10px;
                padding-left: 5px;
            }
    
            input:focus {
                background: #e9e9e9;
                border-radius: 10px;
                border: solid 1px rgb(163, 163, 163);

            }

            select {
                width: 100%;
                height: 40px;
                background: #f7f7f7;
                color: #3d3d3d;
                border: none;
                border-radius: 10px;
                padding-left: 5px;
            }
        }
    }
}

.container-detalhes {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    background-color:map-get($colors, branco);
    

    .row-detalhes {
        width: 1000px;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .box-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .header-perfil {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-get($colors, roxoPrimario);
        margin-bottom: 25px;

        i {
            margin-right: 10px;
        }

        h5 {
            margin: 0px;
        }
    }

    .box-inf-not {
        width:100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        .header-inf-not {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h5 {
                margin-top: 8px;
                margin-left: 5px;
            }
        }

        button {
            width: 100%;
            height: 40px;
            margin-bottom: 20px;
            border: none;
            border-radius: 25px;
            font-weight: 600;
            cursor: pointer;

            i {
                margin-right: 10px;
            }
        }

        

        .box-inf-tx {
            width: 100%;
            height: 50px;
            border: solid 1px rgb(167, 167, 167);
            border-left: none;
            border-right: none;
            border-top: none;
            margin-bottom: 5px;
            margin-top: 5px;

            small {
                color: rgb(37, 37, 37);
            }

            p {
                font-weight: 400;
                font-size: 18px;
            }
        }
    }

    
}

@media (max-width:1366px) {
    .container-detalhes {
    
        .row-detalhes {
            width: 800px;

        }
        
    }
}